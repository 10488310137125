import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const HomeView = () => import('../views/HomeView');
const PriceView = () => import('../views/PriceView');
const ContactView = () => import('../views/ContactView');
const GalleryView = () => import('../views/GalleryView');
const TyresoBostaderView = () => import('../views/TyresoBostaderView');
const InteractiveTenderView = () => import('../views/InteractiveTenderView');


const routes = [{
  path: '/',
  component: HomeView,
  name: 'home'
},
{
  path: '/price',
  component: PriceView,
  name: 'price'
},
{
  path: '/priceInteractive',
  component: InteractiveTenderView,
  name: 'priceInteractive'
},
{
  path: '/contact',
  component: ContactView,
  name: 'contact'
},
{
  path: '/gallery',
  component: GalleryView,
  name: 'gallery'
},
{
  path: '/tybo',
  component: TyresoBostaderView,
  name: 'tybo'
}]

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;