<template>
  <div id="consense" v-if="!consense">
    <p>Vi använder cookies för statistik, analys och för att ge dig bästa möjliga upplevelse. Genom att besöka vår webbplats godkänner du att vi använder cookies. Läs mer i vår <a href="/privacy.html">Privacy policy</a>. <b-button @click="addConsense">Godkänn</b-button></p>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Cookies from 'js-cookie'

export default {
  name: "Consense",
  components: {
    BButton
  },
  data: function () {
    return {
      consense: false
    }
  },
  mounted: function () {
    //Check for cookie
    if(Cookies.get('consent')){
      this.consense = true
    }
  },
  methods: {
    addConsense() {
      Cookies.set('consent', true, { expires: 365 })
      this.consense = true
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#consense {
  margin-top: 10px;
}
</style>
