import Vue from 'vue'
import App from './App.vue'
import router from './router';

//BootstrapVue
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
