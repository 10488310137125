<template>
  <div id="app">
    <Consense></Consense>
    <Header></Header>
    <b-container fluid id="mainArea">
      <router-view></router-view>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>

import Header from './components/Header'
import Footer from './components/Footer'
import Consense from './components/Consense'

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Consense
  }
}
</script>

<style lang="scss">
@import './assets/custom.scss';
#mainArea {
  background-color: #f6f6f7;
  padding: 20px 0px;
}
</style>
